import { Button, Col, Form, Input, Modal, Row, Select, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_API_SAM_URL,
  REACT_APP_API_TENANTS_URL,
} from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleOutlined, InfoCircleOutlined } from "@ant-design/icons/lib/icons";
import { includes } from "lodash";
import Log from "../../did/log";

const CreatePolicyV3 = () => {
  const [policyName, setPolicyName] = useState();
  const [policyType, setPolicyType] = useState("ad");
  const [serviceAccountType, setServiceAccountType] = useState("ad");
  const [formData, setFormData] = useState();
  const [endpointType, setEndpointType] = useState("option1");
  const [endpointList, setEndpointList] = useState([]);
  const [epmGroupsList, setEpmGroupsList] = useState([]);
  const [endpointUserType, setEndpointUserType] = useState("option1");
  const [epmUsersList, setEpmUsersList] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [ouList, setOuList] = useState([]);
  const [adUserList, setAdUserList] = useState([]);
  const [directoryType, setDirectoryType] = useState("option1");
  const [directoryGroupList, setDirectoryGroupList] = useState([]);
  const [directoryUser, setDirectoryUser] = useState([]);
  const [walletList, setwalletList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [adDomain, setAdDomain] = useState("");
  const [adOu, setAdOu] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [checkPolicyNotification, setCheckPolicyNotification] = useState(false);
  const [serviceAccountNameList, setServiceAccountNameList] = useState([]);
  const [credentialData, setCredentialData] = useState([]);
  const [serviceAccountADUserList, setServiceAccountADUserList] = useState([]);
  const [serviceAccountADOuList, setServiceAccountADOuList] = useState([]);

  const { Option } = Select;

  // Function to generate a dynamic name based on a template
  const generateDynamicPolicyName = (prefix, length) => {
    const characters = "0123456789";
    let result = prefix; // Fixed part

    const sections = [7, 8]; // Length of each hyphen-separated section
    for (let sectionLength of sections) {
      for (let i = 0; i < sectionLength; i++) {
        const randomCharIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomCharIndex);
      }
      if (sectionLength !== 8) {
        // Add hyphen except for the last section
        result += "-";
      }
    }
    console.log(result);
    return setPolicyName(result);
  };
  useEffect(() => {
    setFormData([
      { name: ["policyName"], value: policyName },
      { name: ["policyType"], value: "ad" },
      { name: ["serviceAccountType"], value: "ad" },
      {
        name: ["action"],
        value: "Allow",
      },
      {
        name: ["endpointType"],
        value: "option1",
      },
      {
        name: ["endpointUserType"],
        value: "option1",
      },
      {
        name: ["directoryType"],
        value: "option1",
      },
      {
        name: ["walletUsers"],
        value: [localStorage.getItem("UserName")],
      },
      {
        name: ["authorizationRequired"],
        value: 1,
      },
    ]);
  }, [policyName]);
  useEffect(() => {
    generateDynamicPolicyName("", 36);
    fetchEndpoints();
    fetchEndpointGroups();
    fetchEndpointUser();
    listDomain();
    fetchDirectoryGrp();
    fetchWalletUser();
    fetchLog();
  }, []);
  const [modal, contextHolder] = Modal.useModal();

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const serviceAccountNameInfo = (val) => {
    modal.info({
      // title: "Confirm",
      // icon: <ExclamationCircleOutlined />,
      content: `Endpoint : ${val} don't have Service Account User , Please select some other endpoint`,
      okText: "Ok",
    });
  };
  const fetchServiceAccount = (insId, val) => {
    let data = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageId: 1,
      pageSize: 100,
      instanceIds: insId ? insId : [],
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
    };

    axios
      .post(`${REACT_APP_API_PAM_URL}/endpointUsers/listAllEpmUsers`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(val);

        const serviceAccountName = res?.data?.epmUsers
          ?.filter((user) => user.userType.toLowerCase() === "service")
          .map((user) => ({ ...user }));
        if (serviceAccountName.length === 0) {
          serviceAccountNameInfo(val.map((item) => item.value));
        }
        setServiceAccountNameList(serviceAccountName);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        }
      });
  };
  const fetchEndpoints = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoints",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setEndpointList(data.data.endpoints);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };

  const fetchEndpointGroups = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoint-groups",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setEpmGroupsList(data.data.groups);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  const fetchEndpointUser = (page) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoint-users",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data);
        setEpmUsersList(data.data.users);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  const listDomain = () => {
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-domains",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        const inputArray = res.data.data.adDomains;

        const outputArray = inputArray.map((item) => {
          const dcParts = item.domain.split(",").map((part) => part.split("=")[1]);
          const domain = dcParts.join(".");
          return { id: item.id, value: domain, ou: item.ous };
        });

        setDomainList(inputArray);
        console.log({ outputArray });
      });
  };

  const fetchAdUser = (ouDn) => {
    let data = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-users",
      ...ouDn,
      // adDomainId: 4,
      // adOuId: 12,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        console.log(data.data.adUsers);
        setAdUserList(data.data.adUsers);
      })
      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
      });
  };
  const fetchLog = () => {
    let pageDetails = {
      tenantId: DOMAIN_ID(),
      filter: "",
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API}/ad/GetAllDomains`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        const ADData = res?.data?.domains?.map((ad, i) => ({
          // id: i + 1,
          ...ad,
        }));

        setCredentialData(ADData ? ADData : []);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };
  const fetchDirectoryGrp = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      instanceId: 0,
      Filter: {
        filterBy: "adGroups",
        value: "",
      },
      orgId: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_API_PAM_URL}/groups/listAll`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        let Data;
        if (res.data.groups) {
          Data = res.data.groups.map((grp, i) => ({
            index: i,
            id: grp.id,
            name: grp.groupName,

            ...grp,
          }));
        }
        setDirectoryGroupList(Data);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        }
      });
  };
  const handleADUser = (val) => {
    if (directoryUser.length > 0 && directoryUser.includes("all")) {
      // If "all" was previously selected and is now deselected, remove it from the array
      const filteredValues = val.filter((item) => item !== "all");
      setDirectoryUser(filteredValues);
    } else if (val.includes("all")) {
      // If "all" is selected, reset the directoryUser array
      setDirectoryUser(["all"]);
    } else {
      setDirectoryUser(val);
    }
  };

  const fetchServiceAccountUser = (val) => {
    let pageDetails = {
      adId: val.id,
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API}/ad/GetServiceAccountUserByDomain`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setServiceAccountADUserList(res.data.users);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        }
      });
  };

  const fetchWalletUser = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageNumber: 1,
      pageSize: 100,
      search: "",
      filter: "",
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/walletUserList`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setwalletList(res.data.users);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        }
      });
  };

  const constructPayload = (val) => {
    let endpointUser;
    if (val.policyType === "ServiceAccount" && val.serviceAccountType === "local") {
      endpointUser = {
        selectionType: "user",
        selectionValue: val.serviceAccountName,
        match: "exact",
      };
    } else {
      endpointUser = {
        selectionType: endpointUserType === "option5" ? "tenant" : "user",
        selectionValue:
          endpointUserType === "option5"
            ? ["all"]
            : endpointUserType === "option3" || endpointUserType == "option4"
            ? [val.selectionValueEpmUser]
            : val.selectionValueEpmUser
            ? val.selectionValueEpmUser
            : [],
        match: endpointUserType === "option1" || endpointUserType === "option5" ? "exact" : "wild",
      };
    }
    let directoryUser;
    if (val.policyType === "ServiceAccount" && val.serviceAccountType === "ad") {
      directoryUser = {
        selectionType: "user",
        selectionValue: val.serviceAccountName ? val.serviceAccountName : "",
        match: "exact",
      };
    } else {
      directoryUser = {
        selectionType:
          directoryType == "option1" || directoryType == "option4"
            ? "user"
            : directoryType === "option2" || directoryType === "option3"
            ? "groups"
            : "tenant",
        selectionValue:
          directoryType == "option1"
            ? directoryUser
            : directoryType == "option5"
            ? ["all"]
            : directoryType == "option3" || directoryType == "option4"
            ? [val.directoryGroup]
            : val.directoryGroup,
        match:
          directoryType === "option1" || directoryType === "option2" || directoryType === "option5"
            ? "exact"
            : "wild",
      };
    }

    // let endpointUser =
    //   val.policyType === "ServiceAccount"
    //     ? {
    //         selectionType: "user",
    //         selectionValue: val.serviceAccountName,
    //         match: "exact",
    //       }
    //     : {
    //         selectionType: endpointUserType === "option5" ? "tenant" : "user",
    //         selectionValue:
    //           endpointUserType === "option5"
    //             ? ["all"]
    //             : endpointUserType === "option3" || endpointUserType == "option4"
    //             ? [val.selectionValueEpmUser]
    //             : val.selectionValueEpmUser
    //             ? val.selectionValueEpmUser
    //             : [],
    //         match:
    //           endpointUserType === "option1" || endpointUserType === "option5" ? "exact" : "wild",
    //       };
    // let directoryUser =
    //   val.policyType === "ServiceAccount"
    //     ? val.serviceAccountType === "ad" && {
    //         selectionType: "user",
    //         selectionValue: val.serviceAccountName,
    //         match: "exact",
    //       }
    //     : {
    //         selectionType:
    //           directoryType == "option1" || directoryType == "option4"
    //             ? "user"
    //             : directoryType === "option2" || directoryType === "option3"
    //             ? "groups"
    //             : "tenant",
    //         selectionValue:
    //           directoryType == "option1"
    //             ? directoryUser
    //             : directoryType == "option5"
    //             ? ["all"]
    //             : directoryType == "option3" || directoryType == "option4"
    //             ? [val.directoryGroup]
    //             : val.directoryGroup,
    //         match:
    //           directoryType === "option1" ||
    //           directoryType === "option2" ||
    //           directoryType === "option5"
    //             ? "exact"
    //             : "wild",
    //       };
    let data = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      action: val.action,
      policyName: policyName,
      policyType: val.policyType,
      protocol: [],
      os: "",
      adOu: adOu ? adOu : "",
      adDomain: adDomain ? adDomain : "",
      sAMAccountName: false,
      userOrGroups: directoryUser,
      endpointOrGroups: {
        source: {
          match: "exact",
          selectionType: "endpoint",
          selectionValue: val.sourceEndpoint,
        },

        destination: {
          selectionType:
            endpointType === "option1" || endpointType === "option4"
              ? "endpoint"
              : endpointType === "option2" || endpointType === "option3"
              ? "group"
              : "tenant",
          selectionValue:
            endpointType === "option5"
              ? ["all"]
              : endpointType === "option3" || endpointType == "option4"
              ? [val.selectionValueEndpoint]
              : val.selectionValueEndpoint,
          match:
            endpointType === "option1" || endpointType === "option2" || endpointType === "option5"
              ? "exact"
              : "wild",
        },
      },
      endpointUsers: endpointUser,
      // {
      //   selectionType: endpointUserType === "option5" ? "tenant" : "user",
      //   selectionValue:
      //     endpointUserType === "option5"
      //       ? ["all"]
      //       : endpointUserType === "option3" || endpointUserType == "option4"
      //       ? [val.selectionValueEpmUser]
      //       : val.selectionValueEpmUser
      //       ? val.selectionValueEpmUser
      //       : [],
      //   match: endpointUserType === "option1" || endpointUserType === "option5" ? "exact" : "wild",
      // },
      walletUsers: val.walletUsers,
      adDomainId: val.adDomainId && val.adDomainId,
      adOuId: val.adOuId && val.adOuId,
      requireAuthorization: val.authorizationRequired === 1 ? "true" : "false",
    };
    return data;
  };

  const handlePolicySubmit = (val) => {
    const payload = constructPayload(val);
    setIsLoading(true);
    axios
      .post(
        `${REACT_APP_API_DID_URL}/policyService/IsPolicyExists`,
        { ...payload, update: 0 },
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.data.isPolicyExists == true) {
          setCheckPolicyNotification(true);
        } else {
          createPolicy(payload);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.message === "Invalid credentials") {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to create now, PLease try again",
          });
        }
      });
  };
  const createPolicy = (payload) => {
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/CreatePolicy`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        notification.open({
          type: "success",
          content: `${policyName} policy created successfully`,
        });
        setTimeout(() => {
          navigate("/pam/policy");
        }, 3000);
      })

      .catch((err) => {
        if (
          err?.response?.status === 401 ||
          err.response.data?.Message === "Invalid credentials" ||
          err.response.data?.Message === "Unauthorized"
        ) {
          logOutUser();
        }
        notification.open({
          type: "error",
          content: `Unable to create ${policyName} policies`,
        });
      });
  };

  return (
    <>
      {setNotification}
      {checkPolicyNotification && (
        <Modal
          title={`Policy already exists for this combination, Please Create with some other combination`}
          open={checkPolicyNotification}
          onOk={() => {
            setCheckPolicyNotification(false);
          }}
          centered
          closeIcon={false}
          footer={[
            <Button
              key="submit"
              type="primary"
              loading={isLoading}
              onClick={() => setCheckPolicyNotification(false)}
            >
              Ok
            </Button>,
          ]}
        ></Modal>
      )}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Create Policy</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form
                form={form}
                layout="vertical"
                fields={formData}
                onFieldsChange={(_, allFields) => {
                  setFormData(allFields);
                }}
                onFinish={handlePolicySubmit}
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="policyName"
                      label="Policy Name"
                      rules={[
                        {
                          required: true,
                          message: "Policy name is required",
                        },
                      ]}
                    >
                      <Input disabled value={policyName} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="policyType"
                      label="Policy Type"
                      rules={[
                        {
                          required: true,
                          message: "Select policy type",
                        },
                      ]}
                      // initialValue="ad"
                    >
                      <Select
                        showSearch
                        placeholder="Search  Policy Type"
                        optionFilterProp="children"
                        onChange={(e) => {
                          setPolicyType(e);
                          setAdOu("");
                          setAdDomain("");
                          setFormData([
                            { name: ["policyName"], value: policyName },
                            { name: ["policyType"], value: e },
                            { name: ["serviceAccountType"], value: serviceAccountType },
                            {
                              name: ["action"],
                              value: "Allow",
                            },
                            {
                              name: ["endpointType"],
                              value: "option1",
                            },
                            {
                              name: ["endpointUserType"],
                              value: "option1",
                            },
                            {
                              name: ["directoryType"],
                              value: "option1",
                            },
                            {
                              name: ["walletUsers"],
                              value: [localStorage.getItem("UserName")],
                            },
                            {
                              name: ["adDomainId"],
                              value: null,
                            },
                            {
                              name: ["adOuId"],
                              value: null,
                            },
                            {
                              name: ["selectionValueEndpoint"],
                              value: null,
                            },
                          ]);
                          form.setFieldsValue({
                            policyType: e,
                          });
                        }}
                      >
                        <Option value="ad">Active Directory</Option>
                        <Option value="local">Local Users</Option>
                        <Option value="ServiceAccount">Service Account</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {policyType === "ServiceAccount" && (
                  <Row>
                    <Col span={8}>
                      <Form.Item
                        name="serviceAccountType"
                        label="Service Account Type"
                        rules={[
                          {
                            required: true,
                            message: "Select Account type",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Search  Account Type"
                          optionFilterProp="children"
                          onChange={(e) => {
                            setServiceAccountType(e);
                            form.setFieldsValue({
                              policyType: e,
                            });
                          }}
                        >
                          <Option value="ad">Active Directory</Option>
                          <Option value="local">Local Users</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <h3 style={{ color: "blue", marginTop: "1rem" }}>Resources that can be accessed</h3>
                <>
                  {policyType === "ServiceAccount" ? (
                    <Row gutter={16} style={{ marginTop: "1rem" }}>
                      <Col span={8}>
                        <Form.Item
                          name="endpointType"
                          label="Endpoints"
                          rules={[
                            {
                              required: false,
                              message: "Select any option",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Endpoint"
                            onChange={(e) => {
                              form.setFieldsValue({
                                type2: e,
                              });
                              endpointType(e);
                            }}
                            defaultValue={endpointType}
                          >
                            <Option value="option1">Endpoints</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      {endpointType === "option1" && (
                        <Col span={8}>
                          <Form.Item
                            name="selectionValueEndpoint"
                            label="Destination Endpoints List"
                            rules={[
                              {
                                required: true,
                                message: "Select Endpoints",
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              onChange={(e, val) => {
                                setServiceAccountNameList([]);
                                fetchServiceAccount(
                                  val.map((item) => Number(item.key)),
                                  val
                                );
                                form.setFieldsValue({
                                  selectionValueEndpoint: e,
                                });
                              }}
                              defaultValue={form.getFieldValue("selectionValueEndpoint")}
                            >
                              {endpointList &&
                                endpointList.length > 0 &&
                                endpointList.map((endpoint) => (
                                  <Option value={endpoint.name} key={endpoint.id}>
                                    {endpoint.name}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  ) : (
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          name="endpointType"
                          label="Endpoints and Endpoint Groups"
                          rules={[
                            {
                              required: true,
                              message: "Select endpoints and endpoint Groups",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Endpoint And Endpoint Groups"
                            onChange={(e) => setEndpointType(e)}
                            defaultValue={endpointType}
                          >
                            <Option value="option1">Endpoints</Option>
                            <Option value="option2">Endpoint Groups</Option>
                            <Option value="option3">Endpoint Groups Matching Wild Card</Option>
                            <Option value="option4">Endpoint Matching Wild Card</Option>
                            <Option value="option5">Apply To Whole Tenant</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      {endpointType === "option1" ? (
                        <Col span={8}>
                          <Form.Item
                            name="selectionValueEndpoint"
                            label="Endpoints"
                            rules={[
                              {
                                required: true,
                                message: "Select endpoints",
                              },
                            ]}
                          >
                            {" "}
                            <Select
                              mode="multiple"
                              showSearch
                              onChange={(e) =>
                                form.setFieldsValue({
                                  selectionValueEndpoint: e,
                                })
                              }
                              placeholder="Select endpoints"
                            >
                              {endpointList &&
                                endpointList.length > 0 &&
                                endpointList.map((endpoint) => (
                                  <Option value={endpoint.name}>{endpoint.name}</Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      ) : (
                        endpointType === "option2" && (
                          <Col span={8}>
                            <Form.Item
                              name="selectionValueEndpoint"
                              label="Endpoints Groups"
                              rules={[
                                {
                                  required: true,
                                  message: "Select Endpoints Groups",
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                showSearch
                                onChange={(e) =>
                                  form.setFieldsValue({
                                    selectionValueEndpoint: e,
                                  })
                                }
                              >
                                {epmGroupsList?.map((group) => (
                                  <Option value={group.groupName}>{group.groupName}</Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        )
                      )}
                      {(endpointType == "option3" || endpointType == "option4") && (
                        <Col span={8}>
                          <Form.Item
                            name="selectionValueEndpoint"
                            label="Wild Card"
                            rules={[
                              {
                                required: true,
                                message: "Wild Card is required",
                              },
                            ]}
                          >
                            <Input placeholder="prefix*" />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  )}
                </>
                {policyType === "local" && (
                  <Row gutter={16} style={{ marginTop: "1rem" }}>
                    <Col span={8}>
                      <Form.Item
                        name="endpointUserType"
                        label="Endpoints Users"
                        rules={[
                          {
                            required: true,
                            message: "Select Endpoints Users",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Endpoint Users"
                          onChange={(e) => setEndpointUserType(e)}
                          defaultValue={endpointUserType}
                        >
                          <Option value="option1">Endpoint Users</Option>
                          <Option value="option3">Endpoint Users Matching Wild Card</Option>
                          <Option value="option5">Apply To Whole Tenant</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {endpointUserType === "option1" && (
                      <Col span={8}>
                        <Form.Item
                          name="selectionValueEpmUser"
                          label="Endpoints User"
                          rules={[
                            {
                              required: true,
                              message: "Select endpoints user",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            onChange={(e) =>
                              form.setFieldsValue({
                                selectionValueEpmUser: e,
                              })
                            }
                            placeholder="Select endpoint users"
                          >
                            {epmUsersList?.map((user) => (
                              <Option value={user.user}>{user.user}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    {(endpointUserType == "option3" || endpointUserType == "option4") && (
                      <Col span={8}>
                        <Form.Item
                          name="selectionValueEpmUser"
                          label="Wild Card"
                          rules={[
                            {
                              required: true,
                              message: "Wild Card is required",
                            },
                          ]}
                        >
                          <Input placeholder="prefix*" />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                )}
                {policyType === "ad" && (
                  <>
                    <Row gutter={16} style={{ marginTop: "1rem" }}>
                      <Col span={8}>
                        <Form.Item
                          name="adDomainId"
                          label="Domain"
                          rules={[
                            {
                              required: true,
                              message: "Select Domain",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Search Domain"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e, val) => {
                              console.log(val);
                              setAdDomain(val.children);
                              form.setFieldsValue({
                                adDomainId: e,
                              });
                              const selectedDomain = domainList.find((domain) => domain.id === e);
                              if (selectedDomain) {
                                setOuList(
                                  selectedDomain?.ous?.map((data) => {
                                    let ouVal = data.OuName.split(",")[0];
                                    let nameOu = ouVal.substring(3);
                                    return data;
                                  })
                                );

                                fetchAdUser({ adDomainId: parseInt(e) });
                              } else {
                                setOuList([]);
                              }
                            }}
                          >
                            {domainList?.map((dom) => (
                              <Option key={dom.id} value={dom.id}>
                                {dom.directoryName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        {/* <p className="search-label">Ou</p> */}
                        <Form.Item
                          name="adOuId"
                          label="Ou"
                          rules={[
                            {
                              required: false,
                              message: "Select Ou",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Search OU"
                            optionFilterProp="children"
                            onChange={(e, val) => {
                              setAdOu(val.children);
                              form.setFieldsValue({
                                adOuId: e,
                              });
                              console.log(val);
                              let ouDn = {
                                adDomainId: parseInt(val.key),
                                adOuId: e,
                              };
                              fetchAdUser(ouDn);
                            }}
                          >
                            {ouList?.map((ou) => (
                              <Option key={ou.AdId} value={ou.Id}>
                                {ou.OuName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16} style={{ marginTop: "1rem" }}>
                      <Col span={8}>
                        <Form.Item
                          name="directoryType"
                          label="Directory Users and Groups"
                          rules={[
                            {
                              required: true,
                              message: "Select Directory Users",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select User And Groups"
                            onChange={(e) => {
                              setDirectoryType(e);
                              form.setFieldsValue({
                                directoryType: e,
                              });
                            }}
                            defaultValue={directoryType}
                          >
                            <Option value="option1">Directory Users </Option>
                            <Option value="option2">Directory Groups</Option>
                            <Option value="option3">Groups Matching Wild Card</Option>
                            <Option value="option4">Users Matching Wild Card</Option>
                            <Option value="option5">Apply To Whole Tenant</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      {directoryType === "option1" ? (
                        <Col span={8}>
                          {/* <p className="search-label">Directory User List</p> */}
                          <Form.Item
                            name="directoryUsers"
                            label="Directory Users"
                            rules={[
                              {
                                required: true,
                                message: "Select Directory Users",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Select directory user"
                              mode="multiple"
                              onChange={(e) => {
                                handleADUser(e);
                              }}
                            >
                              <>
                                {policyType !== "ad" ? (
                                  <>
                                    {" "}
                                    {adUserList?.map((user) => (
                                      <Option key={user.Id} value={user.UserName}>
                                        {user.UserName}
                                      </Option>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    {/* <Option value="all">Select All</Option> */}
                                    {adUserList?.map((user) => (
                                      <Option key={user.Id} value={user.UserName}>
                                        {user.UserName}
                                      </Option>
                                    ))}
                                  </>
                                )}
                              </>
                            </Select>
                          </Form.Item>
                        </Col>
                      ) : (
                        directoryType === "option2" && (
                          <Col span={8}>
                            <Form.Item
                              name="directoryGroup"
                              label="Directory Groups List"
                              rules={[
                                {
                                  required: true,
                                  message: "Select Directory Groups",
                                },
                              ]}
                            >
                              <Select
                                mode="multiple"
                                onChange={(e) =>
                                  form.setFieldsValue({
                                    directoryGroup: e,
                                  })
                                }
                              >
                                {directoryGroupList?.map((user) => (
                                  <Option value={user.name}>{user.name}</Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        )
                      )}
                      {(directoryType == "option3" || directoryType == "option4") && (
                        <Col span={8}>
                          <Form.Item
                            name="directoryGroup"
                            label="Wild Card"
                            rules={[
                              {
                                required: true,
                                message: "Wild Card is required",
                              },
                            ]}
                          >
                            <Input placeholder="prefix*" />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </>
                )}
                {policyType === "ServiceAccount" && (
                  <>
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          name="SASourceEndpoint"
                          label="Endpoints"
                          rules={[
                            {
                              required: false,
                              message: "Select any option",
                            },
                          ]}
                        >
                          <Select placeholder="Select Endpoint" defaultValue="option1">
                            <Option value="option1">Endpoints</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="sourceEndpoint"
                          label="Source Endpoints List"
                          rules={[
                            {
                              required: true,
                              message: "Select source endpoint",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            onChange={(e) =>
                              form.setFieldsValue({
                                sourceEndpoint: e,
                              })
                            }
                            defaultValue={form.getFieldValue("sourceEndpoint")}
                          >
                            {endpointList &&
                              endpointList.length > 0 &&
                              endpointList.map((endpoint) => (
                                <Option value={endpoint.name}>{endpoint.name}</Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    {serviceAccountType === "ad" && (
                      <Row gutter={16} style={{ marginTop: "1rem" }}>
                        <Col span={8}>
                          <Form.Item
                            name="adDomainId"
                            label="Domain"
                            rules={[
                              {
                                required: true,
                                message: "Select Domain",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search Domain"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(e, val) => {
                                setAdDomain(val.children);
                                form.setFieldsValue({
                                  adDomainId: e,
                                });
                                console.log(val);
                                const selectedDomain = credentialData.find(
                                  (domain) => domain.id === e
                                );
                                console.log(selectedDomain);

                                if (selectedDomain) {
                                  setServiceAccountADOuList([selectedDomain]);
                                } else {
                                  setServiceAccountADOuList([]);
                                }
                                if (serviceAccountType === "ad") {
                                  fetchServiceAccountUser(selectedDomain);
                                }
                              }}
                            >
                              {credentialData?.map((dom) => (
                                <Option key={dom.id} value={dom.id}>
                                  {dom.directoryName}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          {/* <p className="search-label">Ou</p> */}
                          <Form.Item
                            name="adOuId"
                            label="Ou"
                            rules={[
                              {
                                required: false,
                                message: "Select Ou",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search OU"
                              optionFilterProp="children"
                              onChange={(e, val) => {
                                setAdOu(val.children);
                                form.setFieldsValue({
                                  adOuId: e,
                                });
                              }}
                            >
                              {serviceAccountADOuList?.map((ou) => (
                                <Option key={ou?.id} value={ou?.id}>
                                  {ou?.serviceAccountDomain}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item
                          name="serviceAccountName"
                          label="Service Account Name"
                          rules={[
                            {
                              required: true,
                              message: "Select account name",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            onChange={(e) => {
                              form.setFieldsValue({
                                serviceAccountName: e,
                              });
                              console.log(e);
                            }}
                            defaultValue={form.getFieldValue("serviceAccountName")}
                          >
                            {serviceAccountType === "ad"
                              ? serviceAccountADUserList?.map((name) => (
                                  <Option value={name.username}>{name?.username}</Option>
                                ))
                              : serviceAccountNameList?.map((name) => (
                                  <Option value={name.userName}>{name?.userName}</Option>
                                ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="authorizationRequired"
                          label={
                            <span>
                              Authorization Required&nbsp;
                              <Tooltip title="Authorization Required if set to 'yes', notification will be shown in the wallet">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: "Select authorization required",
                            },
                          ]}
                        >
                          <Select
                            onChange={(e) =>
                              form.setFieldsValue({
                                authorizationRequired: e,
                              })
                            }
                            defaultValue={form.getFieldValue("authorizationRequired")}
                          >
                            <Option value={1}>Yes</Option>
                            <Option value={0}>No</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
                <h3 style={{ color: "blue", marginTop: "1rem" }}>Policy applicable to</h3>
                <Row gutter={16} style={{ marginTop: "1rem" }}>
                  <Col span={8}>
                    <Form.Item
                      name="walletUsers"
                      label=""
                      rules={[
                        {
                          required: true,
                          message: "Wallet User is required",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        onChange={(e) =>
                          form.setFieldsValue({
                            walletUsers: e,
                          })
                        }
                        placeholder="Select wallet users"
                        defaultValue={form.getFieldValue("walletUsers")}
                      >
                        {walletList?.map((user) => (
                          <Option value={user.emailAddress}>{user.emailAddress}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <h3 style={{ color: "blue", marginTop: "1rem" }}>Select the policy action</h3>
                <Row>
                  <Col span={8}>
                    <Form.Item
                      name="action"
                      label=""
                      rules={[
                        {
                          required: true,
                          message: "Policy action is required",
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) =>
                          form.setFieldsValue({
                            action: e,
                          })
                        }
                        // defaultValue={actionVal}
                      >
                        <Option value="Allow" success>
                          Allow
                        </Option>
                        <Option value="Deny" danger>
                          Deny
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "2rem",
                    }}
                    span={16}
                  >
                    <Button type="primary" htmlType="submit">
                      Create Policy
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      {contextHolder}
    </>
  );
};

export default CreatePolicyV3;
