import { useState, useEffect } from "react";
import { Row, Col, Input, Tag, Button, Dropdown } from "antd";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { MoreOutlined, InboxOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";
import { AUTH_TOKEN, REACT_APP_PAM_API, ORG_ID, DOMAIN_ID } from "../../../../constants";
import { logOutUser } from "../../../../common";
import { useDebounce } from "../../../../common/debounce";
import AssignEndpointUser from "../../endpointsUsers/AssignEndpointUser";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const EndpointGroupsDetailsUsers = ({ storedValue }) => {
  const [epmUser, setEpmUser] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [selectedRecords, setSelectedRecords] = useState([]);
  const [Isassignwallet, setIsassignwallet] = useState(false);

  const debouncedSearch = useDebounce(search, 500);

  // pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const groupId = queryParams.get("groupId");
  const { groupId } = location.state || {};

  useEffect(() => {
    fetchInstanceGroupDetail();
  }, [debouncedSearch, paginationParams, currentPage]);

  const fetchInstanceGroupDetail = async () => {
    let payload = {
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      domainId: DOMAIN_ID(),
      groupId: Number(groupId ? groupId : storedValue.groupId),
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };

    let config = {
      headers: {
        "X-Authorization": AUTH_TOKEN(),
        withCredentials: true,
      },
    };
    let res;
    try {
      setIsLoading(true);
      res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/fetchInstanceGroupDetail`,
        payload,
        config,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );

      setIsLoading(false);
      fetchEPMUsers(res.data.InstanceGroup.map((ins) => Number(ins.instanceId)));
    } catch (err) {
      setIsLoading(false);
      if (err.response.data.message == "Invalid credentials") {
        logOutUser();
      } else if (err.response.status == 401) {
        logOutUser();
      } else {
      }
    }
  };

  const fetchEPMUsers = (instanceId) => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: search ? 1 : currentPage + 1,
      pageSize: currentPageModel.pageSize,
      token: AUTH_TOKEN(),
      instanceIds: instanceId ? instanceId : [],
      filter: {
        filterBy: "",
        value: "",
      },
      search: search,
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/endpointUsers/listAllEpmUsers`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setEpmUser(
          res?.data?.epmUsers.map((data, index) => ({
            ...data,
            id: index + 1,
          }))
        );
        setTotalCount(res?.data?.totalCount);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.message === "Invalid credentials") {
          logOutUser();
        } else {
        }
      });
  };

  const EndpointStatus = (lastUpdated) => {
    const lastActive = moment.unix(lastUpdated).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
    const diff = moment().diff(lastActive, "minutes");
    let hours = Math.floor(diff / 60);
    let days = Math.floor(hours / 24);
    let minutes = diff % 60;

    let message;

    if (days > 0) {
      message = `Last Active: ${days} days ago`;
    } else if (hours > 0) {
      message = `Last Active: ${hours} hours${
        minutes > 0 ? ` and ${minutes} minutes ago` : " hours ago"
      }`;
    } else {
      message = `Last Active: ${diff} minutes ago`;
    }

    if (diff > 20) {
      return true;
    } else if (diff > 10) {
      return false;
    }
    return false;
  };

  const columns = [
    {
      headerName: "User Name",
      field: "userName",
      key: "userName",
      flex: 1,
    },
    {
      field: "instances",
      headerName: "Endpoints",
      key: "instances",
      flex: 1,
      renderCell: (val) => (
        <span>
          {val?.row?.instances?.map((tag) => {
            const lastActive = moment
              .unix(tag?.lastActive)
              .tz("Asia/Kolkata")
              .format("YYYY-MM-DD HH:mm:ss");
            const diff = moment().diff(lastActive, "minutes");
            return (
              <Tag color={diff >= 20 ? "red" : diff >= 10 ? "orange" : "green"} key={tag}>
                {tag?.hostname}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      headerName: "User Type",
      field: "userType",
      key: "userType",
      flex: 1,
    },
    {
      headerName: "Credentials",
      field: "countOfCredentials",
      key: "countOfCredentials",
      flex: 1,
      renderCell: (val) => {
        const isDisabled = val?.row?.countOfCredentials === 0;
        return (
          <>
            {isDisabled ? (
              <span style={{ cursor: "not-allowed" }}>{val?.row?.countOfCredentials}</span>
            ) : (
              <Link
                to={`/pam/endpointuserDetails`}
                state={{
                  epmUsername: val?.row?.userName,
                  epmUserid: val?.row?.userId,
                }}
                onClick={() => {
                  localStorage.removeItem("details");
                  let values = {
                    epmUsername: val?.row?.userName,
                    epmUserId: val?.row?.userId,
                  };
                  localStorage.setItem("details", JSON.stringify(values));
                }}
              >
                {val?.row?.countOfCredentials}
              </Link>
            )}
          </>
        );
      },
    },
    {
      headerName: "Action",
      key: "action",
      flex: 1,
      renderCell: (val) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "assign",
                label: (
                  <Link
                    onClick={() => {
                      setSelectedRecords(val.row);
                      setIsassignwallet(true);
                    }}
                  >
                    Assign to Wallets
                  </Link>
                ),
              },
              {
                key: "view-session-recording",
                label: (
                  <Link to={`/pam/jumpServer-sessionRecordings?epmUser=${val.row.userName}`}>
                    View Session Recordings
                  </Link>
                ),
              },
              {
                key: "checkout-user",
                label: (
                  <Link
                    to={`/pam/endpointsUsers/checkoutUser/${val.row.userId}`}
                    state={{
                      instanceName: val.row.instances[0].hostname,
                      instanceId: JSON.stringify(val.row.instances[0].instanceId),
                      epmUsername: val.row.userName,
                      os: val.row.instances[0].os,
                    }}
                  >
                    Checkout User
                  </Link>
                ),
                disabled: EndpointStatus(val.row.instances.map((ins) => ins.lastActive)),
              },

              {
                key: "reset-credential",
                label: (
                  <Link
                    to={`/pam/endpointsUsers/reset-credentials?epmuser=${val.row.userName}&epmuserId=${val.row.userId}`}
                  >
                    Force Reset Credentials
                  </Link>
                ),
                danger: true,
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <Row className="content-container">
        <Col span={24}>
          <Row style={{ marginBottom: "2rem", gap: "2rem" }} className="search-box-container">
            <Col span={6}>
              <p className="search-label">Search</p>

              <Input
                style={{ width: "100%" }}
                placeholder="Search"
                allowClear
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  if (paginationParams.current !== 1) {
                    setPaginationParams({ current: 1, pageSize: 10 });
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
            }}
            rows={epmUser}
            columns={columns}
            style={{ border: "none" }}
            loading={isLoading}
            paginationMode="server"
            rowCount={totalCount}
            page={currentPage}
            paginationModel={currentPageModel}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            onPaginationModelChange={(params) => {
              setCurrentPage(params.page);
            }}
          />
        </Col>
      </Row>

      {Isassignwallet && (
        <AssignEndpointUser
          Isassignwallet={Isassignwallet}
          setIsassignwallet={setIsassignwallet}
          selectedRecords={selectedRecords}
        />
      )}
    </>
  );
};

export default EndpointGroupsDetailsUsers;
