import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

import { HomeOutlined, UserOutlined } from "@ant-design/icons";

import EndpointGroupsDetailsEndpoints from "./endpointGroupsTabs/EndpointGroupsDetailsEndpoints";
import EndpointGroupsDetailsUsers from "./endpointGroupsTabs/EndpointGroupsDetailsUsers";
import EndpointGroupDetailsAuth from "./endpointGroupsTabs/EndpointGroupDetailsAuth";

import { Col, Row, Tabs, Breadcrumb } from "antd";

const EndpointGroupDetails = () => {
  const [selectedTab, setSelectedTab] = useState("1");

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabSelected = queryParams.get("selectedTab");
  // const groupName = queryParams.get("groupName");

  const { groupName } = location.state || {};

  const Storage = localStorage.getItem("details");
  const storedValue = JSON.parse(Storage);

  useEffect(() => {
    handleQueryParam();
  }, []);

  const handleTab = (key) => {
    setSelectedTab(key);
  };

  const tabs = [
    {
      key: "1",
      label: "Endpoints",
      children: <EndpointGroupsDetailsEndpoints storedValue={storedValue} />,
    },
    {
      key: "2",
      label: "Endpoints User",
      children: <EndpointGroupsDetailsUsers storedValue={storedValue} />,
    },
    {
      key: "3",
      label: "Auth Flow",
      children: <EndpointGroupDetailsAuth storedValue={storedValue} />,
    },
  ];

  const handleQueryParam = () => {
    if (tabSelected === "endpoint") {
      setSelectedTab("1");
    } else if (tabSelected === "endpointUsers") {
      setSelectedTab("2");
    } else if (tabSelected === "authFlow") {
      setSelectedTab("3");
    }
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row style={{ marginBottom: "1rem" }}>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Link to="/">
                      <HomeOutlined />
                    </Link>
                  ),
                },
                {
                  title: (
                    <>
                      <UserOutlined />
                      <Link onClick={() => navigate(-1)}>
                        <span>Endpoints Group</span>
                      </Link>
                    </>
                  ),
                },
                {
                  title: "Endpoint Group Details",
                },
              ]}
            />
          </Row>
          <Row justify="space-between">
            <Col>
              <h2 className="title">
                Endpoint Group Details -{" "}
                <span
                  style={{ color: "rgb(22, 119, 255)", cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                >
                  {`${groupName ? groupName : storedValue.groupName}`}{" "}
                </span>
              </h2>
            </Col>
          </Row>
          <Row className="tab-container">
            <Col span={24}>
              <Tabs activeKey={selectedTab} onChange={handleTab} items={tabs} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EndpointGroupDetails;
