import { Button, Col, Form, Input, Modal, Row, Select, Space, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN, AUTH_TOKEN_ORG, REACT_APP_API_TENANTS_URL } from "../../constants";
import OktaSetup from "./settings/OktaSetup";

const AddTenant = () => {
  const [notification, setNotification] = message.useMessage();
  const [tenantName, setTenantName] = useState("");
  const [formData, setFormData] = useState();
  const [orgName, setOrgName] = useState("");
  const [authMethod, setAuthMethod] = useState();
  const [isOpenViewAuthMethod, setIsOpenViewAuthMethod] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    setFormData([{ name: ["url"], value: `${tenantName}.${orgName}.authnull.com` }]);
  }, [tenantName, formData]);
  useEffect(() => {
    fetchOrgDetail();
  }, []);

  const fetchOrgDetail = () => {
    let data = {
      email: localStorage.getItem("UserName"),
    };

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/orgdetail`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN_ORG(),
        },
      })
      .then((res) => {
        setOrgName(res.data.data.organization_name);
      });
  };
  const handleAuthMethod = (val) => {
    console.log({ val });
    setAuthMethod(val);
    form.setFieldsValue({
      authenticationMethod: val,
    });
  };
  const handleCreateTenant = (e) => {
    let data = {
      ...e,
      createdby: localStorage.getItem("UserName"),
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/createtenant`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN_ORG(),
        },
      })
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: "Tenant created successfully",
        });
        setTimeout(() => {
          navigate("/tenant");
        }, 1000);
      })
      .catch((err) => {
        notification.open({
          type: "error",
          content: "Unable to create tenant, PLease try again sometimes later",
        });
      });
  };
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={20}>
          <Row align="middle" justify="space-between">
            <Col>
              <h2 className="title">Create Tenant</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form
                form={form}
                layout="vertical"
                onFinish={handleCreateTenant}
                fields={formData}
                onFieldsChange={(_, allFields) => {
                  setFormData(allFields);
                }}
              >
                <div>
                  <Row justify="space-between" align="middle">
                    <Col span={11}>
                      <Form.Item
                        name="tenantname"
                        label="Tenant Name"
                        rules={[
                          {
                            required: true,
                            message: "Tenant Name is required",
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) => {
                            setTenantName(e.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                          {
                            required: true,
                            message: "E-mail is required",
                          },
                          {
                            type: "email",
                            message: "Enter a valid email address",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between" align="middle">
                    <Col span={11}>
                      <Form.Item
                        name="url"
                        label="URL"
                        rules={[
                          {
                            required: true,
                            message: "URL is required",
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        name="authenticationmethod"
                        label="Authentication Method"
                        rules={[
                          {
                            required: true,
                            message: "Select authentication method",
                          },
                        ]}
                      >
                        <Select
                          onChange={handleAuthMethod}
                          placeholder="Select authentication method"
                          tokenSeparators={[","]}
                          showSearch
                          optionFilterProp="label"
                          options={[
                            {
                              value: "Okta",
                              label: "SAML 2.0",
                            },
                            {
                              value: "ADWallet",
                              label: "Active Directory",
                            },
                            {
                              value: "Normal",
                              label: "Basic Password Method",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {authMethod === "Okta" && (
                    <Row justify="space-between" align="middle">
                      <Col span={11}>
                        <Form.Item
                          name="ssoUrl"
                          label="SSO URL"
                          rules={[
                            {
                              required: true,
                              message: "URL is required",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <label>
                          <a onClick={() => setIsOpenViewAuthMethod(true)}>
                            View Instruction to setup SAML 2.0 app{" "}
                          </a>
                        </label>
                      </Col>
                    </Row>
                  )}
                </div>
                <Row justify="end">
                  <Col>
                    <Space>
                      <Button onClick={() => navigate(-1)}>Cancel</Button>
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      {isOpenViewAuthMethod && (
        <Modal
          title="SAML 2.0 App Creation"
          open={isOpenViewAuthMethod}
          onOk={() => setIsOpenViewAuthMethod(false)}
          onCancel={() => setIsOpenViewAuthMethod(false)}
          footer={null}
          width={900}
          style={{
            top: 20,
            height: "599px",
          }}
        >
          <OktaSetup tenantName={tenantName} orgName={orgName} />
        </Modal>
      )}
    </>
  );
};

export default AddTenant;
