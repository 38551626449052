import {
  Col,
  Row,
  Select,
  Button,
  Input,
  Tag,
  DatePicker,
  Collapse,
  Typography,
} from "antd";
import { useState } from "react";
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Paragraph } = Typography;

const ApplicationRequest = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };
  const endpoints = [
    {
      policyName: "policy-1",
      lastChange: "10/10/2023 04:50pm PST",
      platform: "AWS",
      count: "5",
      description: "Policies for AWS JIT access",
      status: "active",
    },
    {
      policyName: "policy-2",
      lastChange: "10/11/2023 05:30pm PST",
      platform: "Azure",
      count: "8",
      description: "Azure security policies",
      status: "active",
    },
    {
      policyName: "policy-3",
      lastChange: "10/12/2023 03:20pm PST",
      platform: "GCP",
      count: "12",
      description: "Google Cloud security rules",
      status: "inactive",
    },
    {
      policyName: "policy-4",
      lastChange: "10/13/2023 06:45pm PST",
      platform: "AWS",
      count: "3",
      description: "AWS IAM policies",
      status: "active",
    },
    {
      policyName: "policy-5",
      lastChange: "10/14/2023 02:15pm PST",
      platform: "Azure",
      count: "6",
      description: "Advanced Azure policies",
      status: "inactive",
    },
    {
      policyName: "policy-6",
      lastChange: "10/15/2023 04:10pm PST",
      platform: "AWS",
      count: "9",
      description: "AWS security rules",
      status: "active",
    },
    {
      policyName: "policy-7",
      lastChange: "10/16/2023 05:25pm PST",
      platform: "GCP",
      count: "7",
      description: "GCP access policies",
      status: "active",
    },
    {
      policyName: "policy-8",
      lastChange: "10/17/2023 03:55pm PST",
      platform: "Azure",
      count: "4",
      description: "Azure access control",
      status: "inactive",
    },
    {
      policyName: "policy-9",
      lastChange: "10/18/2023 06:30pm PST",
      platform: "AWS",
      count: "11",
      description: "AWS security best practices",
      status: "active",
    },
    {
      policyName: "policy-10",
      lastChange: "10/19/2023 02:40pm PST",
      platform: "GCP",
      count: "10",
      description: "GCP security policies",
      status: "active",
    },
  ];

  const columns = [
    { title: "Policy Name", dataIndex: "policyName", key: "policyName" },
    {
      dataIndex: "lastChange",
      title: "Last Change",
      key: "lastChange",
    },

    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
    },
    {
      title: "Rule Count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (tags, record) => (
        <span>
          {record.status == "active" ? (
            <Tag color={"green"}>Live</Tag>
          ) : (
            <Tag color={"black"}>Audit</Tag>
          )}
        </span>
      ),
    },

    {
      title: "Logs",
      key: "logs",
      render: (_value, record) => <a>View Logs</a>,
    },
  ];

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">View Application Request</h2>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <p className="search-label">User Name</p>
              <Input
                placeholder="Enter User Name"
                onSearch={(value) => console.log(value)}
              />
            </Col>
            <Col span={6}>
              <p className="search-label">UAC Title</p>
              <Input
                placeholder="Enter UAC Title"
                onSearch={(value) => console.log(value)}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <p className="search-label">Tenant</p>
              <Input
                placeholder="Enter Tenant"
                onSearch={(value) => console.log(value)}
              />
            </Col>
            <Col span={6}>
              <p className="search-label">Name</p>
              <Input
                placeholder="Enter Name"
                onSearch={(value) => console.log(value)}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <p className="search-label">Location</p>
              <Input
                placeholder="Enter Location"
                onSearch={(value) => console.log(value)}
              />
            </Col>
            <Col span={6}>
              <p className="search-label">Publisher Name</p>
              <Input
                placeholder="Enter Publisher Name"
                onSearch={(value) => console.log(value)}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <p className="search-label">Previlege Level</p>
              <Input
                placeholder="Enter Previlege Level"
                onSearch={(value) => console.log(value)}
              />
            </Col>
            <Col span={6}>
              <p className="search-label">File Path</p>
              <Input
                placeholder="Enter File Path"
                onSearch={(value) => console.log(value)}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <p className="search-label">Endpoint Name</p>
              <Input
                placeholder="Enter Endpoint Name"
                onSearch={(value) => console.log(value)}
              />
            </Col>
            <Col span={6}>
              <p className="search-label">MD5 Hash</p>
              <Input
                placeholder="Enter MD5 Hash"
                onSearch={(value) => console.log(value)}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <p className="search-label">Request Date</p>
              <DatePicker style={{width:'100%'}} />
            </Col>
            <Col span={6}>
              <p className="search-label">See Virus Total Defination</p>
              <a>Click To View</a>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <p className="search-label">Respond Date</p>
              <DatePicker style={{width:'100%'}} />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <p className="search-label">Decision</p>
              <Select>
                <Option value="dec1">Allow</Option>
                <Option value="dec2">Disallow</Option>
              </Select>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}>
              <p className="search-label">Apply</p>
              <Select>
                <Option value="dec1">Global</Option>
                <Option value="dec2">Tenant</Option>
                <Option value="dec2">Endpoint Group</Option>
                <Option value="dec2">Endpoint</Option>
                <Option value="dec2">User Groups Or Users</Option>
              </Select>
            </Col>
          </Row>

          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
              span={2}
              offset={20}
            >
              <Button type="primary">Approve</Button>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
              span={2}
            >
              <Button type="default">Reject</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ApplicationRequest;
