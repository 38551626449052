import { Col, Row, Dropdown, Button, Input, message, Tag, Cascader, Select } from "antd";
import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import {
  AUTH_TOKEN,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_TENANTS_URL,
} from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import { MoreOutlined } from "@ant-design/icons";
import AssignEndpointUser from "./AssignEndpointUser";
import { useDebounce } from "../../../common/debounce";
import moment from "moment-timezone";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const Endpoints = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [epmUsers, setEpmUser] = useState([]);
  const [notification, setNotification] = message.useMessage();
  const [endpointData, setEndpointData] = useState([]);

  const [sortModel, setSortModel] = useState();
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [Isassignwallet, setIsassignwallet] = useState(false);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const debouncedValue = useDebounce(search, 1000);

  //pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });
  const { Option } = Select;

  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }
    const filteredOptions = epmUsers
      .filter((epmuser) => epmuser.userName.toLowerCase().includes(value.toLowerCase()))
      .map((epmuser) => ({
        label: epmuser.userName,
        value: epmuser.userName,
      }));

    setOptions(filteredOptions);
  };

  useEffect(() => {
    fetchEPMUsers();
  }, [paginationParams, filteredValue, debouncedValue, currentPage]);

  useEffect(() => {
    fetchInstance();
  }, []);

  const EndpointStatus = (lastUpdated) => {
    const lastActive = moment.unix(lastUpdated).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
    const diff = moment().diff(lastActive, "minutes");
    let hours = Math.floor(diff / 60);
    let days = Math.floor(hours / 24);
    let minutes = diff % 60;

    let message;

    if (days > 0) {
      message = `Last Active: ${days} days ago`;
    } else if (hours > 0) {
      message = `Last Active: ${hours} hours${
        minutes > 0 ? ` and ${minutes} minutes ago` : " hours ago"
      }`;
    } else {
      message = `Last Active: ${diff} minutes ago`;
    }

    if (diff > 20) {
      return true;
    } else if (diff > 10) {
      return false;
    }
    return false;
  };

  const columns = [
    { headerName: "User Name", field: "userName", key: "userName", flex: 1 },
    {
      field: "epmMachineName",
      headerName: "Endpoints",
      key: "epmMachineName",
      flex: 1,
      renderCell: (val) => {
        const statusOfMachine = moment
          .unix(val?.row?.lastActive)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DD HH:mm:ss");
        const diff = moment().diff(statusOfMachine, "minutes");
        console.log(diff);

        return (
          <Tag
            color={
              val?.row?.lastActive === null
                ? "default"
                : diff > 20
                ? "red"
                : diff > 10
                ? "orange"
                : "green"
            }
            key={val?.row?.epmMachineName}
          >
            {val?.row?.epmMachineName}
          </Tag>
        );
      },
    },
    {
      headerName: "Credentials",
      field: "countOffCredentials",
      key: "countOffCredentials",
      flex: 1,
      renderCell: (val) => {
        const isDisabled = val?.row?.countOffCredentials === 0;
        return (
          <>
            {isDisabled ? (
              <span style={{ cursor: "not-allowed" }}>{val?.row?.countOffCredentials}</span>
            ) : (
              <Link
                to={`/pam/endpointuserDetails`}
                state={{
                  epmUsername: val?.row?.userName,
                  epmUserId: val?.row?.userId,
                  PublicIp: val?.row?.epmMachinePublicIp,
                  HostName: val?.row?.epmMachineName,
                }}
                onClick={() => {
                  localStorage.removeItem("details");
                  let values = {
                    epmUsername: val?.row?.userName,
                    epmUserId: val?.row?.userId,
                    PublicIp: val?.row?.epmMachinePublicIp,
                    HostName: val?.row?.epmMachineName,
                  };
                  localStorage.setItem("details", JSON.stringify(values));
                }}
              >
                {val?.row?.countOffCredentials}
              </Link>
            )}
          </>
        );
      },
    },

    {
      headerName: "Action",
      field: "action",
      flex: 1,
      renderCell: (val) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "assign",
                label: (
                  <Link
                    state={{
                      PublicIp: val?.row?.epmMachinePublicIp,
                      HostName: val?.row?.epmMachineName,
                    }}
                    onClick={() => {
                      localStorage.removeItem("details");
                      let values = {
                        PublicIp: val?.row?.epmMachinePublicIp,
                        HostName: val?.row?.epmMachineName,
                      };
                      localStorage.setItem("details", JSON.stringify(values));
                      setSelectedRecords(val.row);
                      setIsassignwallet(true);
                    }}
                  >
                    Assign to Wallets
                  </Link>
                ),
              },
              {
                key: "view-session-recording",
                label: (
                  <Link to={`/pam/jumpServer-sessionRecordings?epmUser=${val?.row?.userName}`}>
                    View Session Recordings
                  </Link>
                ),
              },
              {
                key: "checkout-user",
                label: (
                  <Link
                    to={`/pam/endpointsUsers/checkoutUser`}
                    state={{
                      epmUserId: val.row.userId,
                      instanceName: val.row.epmMachineName,
                      instanceId: JSON.stringify(val?.row?.epmMachineId),
                      epmUsername: val?.row?.userName,
                    }}
                    onClick={() => {
                      let values = {
                        epmUserId: val.row.userId,
                        instanceName: val.row.epmMachineName,
                        instanceId: val.row.epmMachineId,
                        epmUsername: val?.row?.userName,
                      };
                      localStorage.setItem("epmUser", JSON.stringify(values));
                    }}
                  >
                    Checkout User
                  </Link>
                ),
                disabled: EndpointStatus(val?.row?.lastActive),
              },

              {
                key: "reset-credential",
                label: (
                  <Link
                    to={`/pam/endpointsUsers/reset-credentials?epmuser=${val.row.userName}&epmuserId=${val.row.userId}`}
                  >
                    Force Reset Credentials
                  </Link>
                ),
                danger: true,
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const fetchEPMUsers = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: search || filterBy ? 1 : currentPage + 1,
      pageSize: currentPageModel.pageSize,
      token: AUTH_TOKEN(),
      instanceIds: filteredValue ? [filteredValue] : [],
      filter: {
        filterBy: filterBy,
        Value: "",
      },
      search,
      orgId: ORG_ID(),
    };
    setIsLoading(true);

    axios
      .post(`${REACT_APP_PAM_API}/endpointUsers/listAllEpmUsers`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);

        const originalEpmUsers = res?.data?.epmUsers;

        const epmUsersWithId = originalEpmUsers.map((user, index) => ({
          ...user,
          id: index + 1,
          countOffCredentials: 0,
          lastActive: null,
        }));
        setEpmUser(epmUsersWithId ?? []);
        setTotalCount(res?.data?.totalCount);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });

        // Assuming the parent API returns an array of items, each with an 'id'
        const ids = originalEpmUsers.map((item) => item.userId); // Extract ids from the response

        const uniqueInstanceIds = Array.from(
          new Set(originalEpmUsers.map((item) => item.epmMachineId))
        ); // Ensure uniqueness

        // Make child API calls for each ID
        const childApiCalls1 = ids.map((id) => fetchCredentialCount(id));
        const childApiCalls2 = uniqueInstanceIds.map((id) => fetchInstanceTime(id));

        Promise.all([Promise.all(childApiCalls1), Promise.all(childApiCalls2)])
          .then(([allChildResponses1, allChildResponses2]) => {
            // Update countOffCredentials based on first child API calls
            let updatedUsers = epmUsersWithId.map((user, index) => ({
              ...user,
              countOffCredentials: allChildResponses1[index]?.data?.count || 0,
            }));

            // Create a map of machineId to lastActive from the second child API responses
            const lastActiveMap = new Map(
              allChildResponses2.map((response) => [
                response.machineId,
                response.data?.lastActive || null,
              ])
            );
            // Update lastActive based on the map
            updatedUsers = updatedUsers.map((user) => {
              const lastActive = lastActiveMap.get(user.epmMachineId) || null;
              return {
                ...user,
                lastActive: lastActive,
              };
            });
            // Update the state with the final users data
            setEpmUser(updatedUsers);
            console.log(updatedUsers, "vv");
          })
          .catch((error) => {
            console.error("Error in child API calls:", error);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoint users",
          });
        }
      });
  };

  const fetchCredentialCount = (id) => {
    let vcRequest = {
      epmUserId: Number(id),
      orgId: ORG_ID(),
      domainId: DOMAIN_ID(),
    };

    return axios
      .post(`${REACT_APP_API_DID_URL}/credential/getCredentialCountByEpmUser`, vcRequest, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((childData) => {
        // Process the child data if needed
        return childData; // Return the child data to be used in Promise.all
      })
      .catch((error) => {
        console.error("Error in child API call for ID", id, ":", error);
      });
  };

  const fetchInstanceTime = (id) => {
    let timeRequest = {
      uuid: "",
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      endpointId: Number(id),
    };

    // Return the axios post promise
    return axios
      .post(`${REACT_APP_API_DID_URL}/getLastActiveOfEndpointMachine`, timeRequest, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((response) => {
        return {
          machineId: id,
          data: response.data,
        };
      })
      .catch((err) => {
        console.log("Error in child API call for instance time:", err);
      });
  };

  const fetchInstance = () => {
    let pageDetails = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoints",
    };

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEndpointData(res?.data?.data?.endpoints);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const displayRender = (labels) => labels[labels.length - 1];

  const filter = (inputValue, path) => {
    console.log(path, inputValue, "inputValue");
    return path.some((option) => {
      const label = option?.label?.props?.children[0]
        ? option.label.props.children[0].toLowerCase()
        : option.label.toLowerCase();
      return label.indexOf(inputValue.toLowerCase()) > -1;
    });
  };

  // const handleFilter = (_, data) => {
  //   console.log(data);
  //   setfilterBy(data ? data[0]?.value : "");
  //   setFilteredValue(data ? data[1]?.value : "");
  //   setPaginationParams({ current: 1, pageSize: 10 });
  // };

  const handleFilter = (value) => {
    console.log(value);

    // Assuming `value` is either the `hostName` or `instanceId`
    if (value) {
      const selectedOption = endpointData.find(
        (option) => option.name === value || option.id === value
      );

      if (selectedOption) {
        setfilterBy("endpoints");
        setFilteredValue(selectedOption.id || "");
      }
    } else {
      setfilterBy("");
      setFilteredValue("");
    }

    setPaginationParams({ current: 1, pageSize: 10 });
  };

  const handleSortModelChange = (model) => {
    console.log(model);
    setSortModel(model);
  };
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Endpoint Users</h2>
            </Col>
            <Col>
              <Link to={"add"}>
                <Button type="primary">Add Users</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">UserName</p>
                      <AutoComplete
                        placeholder="Search UserName"
                        allowClear
                        onSearch={handleSearch}
                        options={options}
                        onChange={(value) => {
                          setSearch(value);
                        }}
                        onClear={(val) => {
                          setCurrentPage(0);
                        }}
                      />

                      {/* <Cascader
                        options={[
                          {
                            value: "endpoints",
                            label: "Endpoints",
                            children: endpointData,
                          },
                        ]}
                        expandTrigger="hover"
                        displayRender={displayRender}
                        onChange={handleFilter}
                        placeholder="Search to select"
                        showSearch={{
                          filter,
                        }}
                      /> */}
                    </Col>
                    <Col span={6}>
                      <p className="search-label">Endpoints</p>

                      <Select
                        showSearch={filter}
                        allowClear
                        placeholder="Select Endpoint"
                        onChange={handleFilter}
                        onClear={(e) => {
                          setfilterBy("");
                          setFilteredValue("");
                          setCurrentPage(0);
                        }}
                      >
                        {endpointData.map((user) => (
                          <Option value={user.name || user.id}>
                            {user?.name} <b>{`(${user?.publicIpAddress})`}</b>
                          </Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={epmUsers}
                columns={columns}
                paginationMode="server"
                rowCount={totalCount}
                loading={isLoading}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                paginationModel={currentPageModel}
                page={currentPage}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Outlet />
      {Isassignwallet && (
        <AssignEndpointUser
          Isassignwallet={Isassignwallet}
          setIsassignwallet={setIsassignwallet}
          selectedRecords={selectedRecords}
          fetchEPMUsers={fetchEPMUsers}
        />
      )}
    </>
  );
};

export default React.memo(Endpoints);
