import { Button, Col, Form, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";

export default function NetworksTab({
  prevTab,
  nextTab,
  setselectedCountry,
  setSelectedState,
  selectedCountry,
  selectedState,
}) {
  const [form] = Form.useForm();
  const [selectedCountryLocal, setSelectedCountryLocal] = useState(null);
  const [selectedStateLocal, setSelectedStateLocal] = useState(null);

  useEffect(() => {
    if (selectedCountry !== "" || selectedState !== "") {
      setSelectedCountryLocal(selectedCountry);
      setSelectedStateLocal(selectedState);
      form.setFieldsValue({
        country: selectedCountry,
        state: selectedState,
      });
    }
  }, [selectedCountry, selectedState, form]);

  const countries = {
    India: ["Karnataka", "Mumbai", "Tamil Nadu", "West Bengal", "Hyderabad", "Kerala"],
    USA: ["Texas", "San Francisco", "Washington", "California"],
    UK: ["England", "Scotland", "Wales", "Northern Ireland"],
    Bangladesh: ["Barishal", "Chattogram", "Dhaka", "Rangpur"],
    Nepal: ["Gandaki Pradesh", "Karnali Pradesh", "Sudurpashchim Pradesh"],
  };

  const handleCountryChange = (value) => {
    setSelectedCountryLocal(value);
    setSelectedStateLocal(null);
    setselectedCountry(value);
  };

  const handleStateChange = (value) => {
    setSelectedStateLocal(value);
    setSelectedState(value);
  };

  const renderConditionalDropdown = () => {
    if (!selectedCountryLocal || !countries[selectedCountryLocal]) {
      return null;
    }

    return (
      <Form.Item
        label=" Select State"
        name="state"
        rules={[{ required: true, message: "Please select a state" }]}
      >
        <Select
          placeholder="Select State"
          value={selectedStateLocal}
          onChange={handleStateChange}
          options={countries[selectedCountryLocal].map((state) => ({
            label: state,
            value: state,
          }))}
        />
      </Form.Item>
    );
  };

  const handleNextClick = () => {
    form
      .validateFields()
      .then(() => {
        setselectedCountry(selectedCountryLocal);
        setSelectedState(selectedStateLocal);
        nextTab();
      })
      .catch(() => {
        message.error("Please fill in the required fields before proceeding");
      });
  };

  return (
    <Row>
      <Col span={24} style={{ padding: "20px" }}>
        <Row>
          <Col
            span={18}
            style={{
              background: "rgb(219, 238, 255)",
              borderRadius: "8px",
              padding: "2rem",
            }}
          >
            <Form form={form} onFinish={handleNextClick} layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Select Country"
                    name="country"
                    rules={[{ required: true, message: "Select a Country" }]}
                  >
                    <Select
                      placeholder="Select Country"
                      value={selectedCountryLocal}
                      onChange={handleCountryChange}
                      options={Object.keys(countries).map((country) => ({
                        label: country,
                        value: country,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>{renderConditionalDropdown()}</Col>
              </Row>
            </Form>
          </Col>
        </Row>

        <Row style={{ marginTop: "1rem" }}>
          <Col span={18}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <Button onClick={() => prevTab()}>Back</Button>
              <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
