import React, { useState, useEffect } from "react";
import { Button, Col, Form, Select, Row } from "antd";
import axios from "axios";
import { REACT_APP_API, ORG_ID, DOMAIN_ID, AUTH_TOKEN } from "../../../../constants.js";

const { Option } = Select;

export default function AppsTab({
  setSelectedType,
  setSelectedApp,
  setSelectedEndpoint,
  setEndpointHostname,
  setSelectedOU,
  selectedOU,
  nextTab,
  selectedEndpoint,
  endpointHostname,
  setEndpointOSType,
}) {
  const [form] = Form.useForm();

  const [selectedTypeLocal, setSelectedTypeLocal] = useState("Endpoints");
  const [selectedAppLocal, setSelectedAppLocal] = useState(null);
  const [selectedEndpointType, setSelectedEndpointType] = useState("Endpoints");

  const [endpoints, setEndpoints] = useState([]);
  const [allOU, setAllOU] = useState([]);

  const handleTypeChange = (value) => {
    setSelectedTypeLocal(value);
    setSelectedType(value);
    setSelectedAppLocal(null);
    setSelectedApp(null);
    setSelectedEndpointType(null);
  };

  const handleAppChange = (value) => {
    setSelectedAppLocal(value);
    setSelectedApp(value);
  };

  const handleEndpointChange = (selectedOption) => {
    if (!selectedOption) return;
    try {
      if (selectedOption) {
        setSelectedEndpoint(selectedOption.value);
        setEndpointHostname(selectedOption.label[0]);
        getEndpointOsType(endpoints, selectedOption.value.split(",")[0].trim());
      }
    } catch (error) {
      console.error("Failed to parse selected option:", error);
    }
  };

  const handleEndpointTypeChange = (value) => {
    setSelectedEndpointType(value);
  };

  const fetchEndpoints = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listEndpoints`,
        {
          org_Id: ORG_ID(),
          tenant_Id: DOMAIN_ID(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      const data = response.data;
      const combinedEndpoints = [...data.l_endpoint, ...data.w_endpoint];
      setEndpoints(combinedEndpoints);
    } catch (error) {
      console.log("Failed to fetch endpoints:", error);
      setEndpoints([]);
    }
  };

  useEffect(() => {
    if (selectedEndpointType === "Endpoints") {
      fetchEndpoints();
      fetchOU();
    }
    if ((selectedOU !== "" && endpointHostname !== "") || selectedEndpoint !== "") {
      form.setFieldsValue({
        endpointType: selectedEndpointType,
        endpointOU: selectedOU,
        endpoints: endpointHostname && `${endpointHostname} (${selectedEndpoint})`,
      });
    }
  }, [selectedEndpointType, selectedOU, endpointHostname, selectedEndpoint, form]);

  useEffect(() => {
    getEndpointOsType(endpoints, selectedEndpoint);
  }, [endpoints, selectedEndpoint]);

  const renderConditionalDropdown = () => {
    if (selectedTypeLocal === "App") {
      return (
        <Form.Item
          label="Select App"
          name="select app"
          rules={[{ required: true, message: "Please select a select app" }]}
        >
          <Select
            placeholder="Select app"
            rules={[{ required: true, message: "Please select an app" }]}
            value={selectedAppLocal}
            onChange={handleAppChange}
            options={[
              { label: "Google Workspace", value: "Google Workspace" },
              { label: "Incorta", value: "Incorta" },
              { label: "JIRA", value: "JIRA" },
              { label: "Box", value: "Box" },
              { label: "Workato", value: "Workato" },
              { label: "Wolken", value: "Wolken" },
            ]}
          />
        </Form.Item>
      );
    } else if (selectedTypeLocal === "Endpoints") {
      return (
        <Form form={form} onFinish={handleNextClick}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Select Endpoint/Endpoints Group"
                name="endpointType"
                rules={[
                  {
                    required: true,
                    message: "Please select a endpoint/endpoints group",
                  },
                ]}
              >
                <Select
                  placeholder="Select endpoint"
                  rules={[
                    {
                      required: true,
                      message: "Please select an endpoint/endpoints Group",
                    },
                  ]}
                  defaultValue={selectedEndpointType}
                  options={[
                    { label: "Endpoints", value: "Endpoints" },
                    { label: "Endpoints Group", value: "Endpoints Group" },
                  ]}
                  onChange={handleEndpointTypeChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {selectedEndpointType === "Endpoints" && (
                <Form.Item
                  name="endpoints"
                  label="Select Endpoint"
                  rules={[{ required: true, message: "Select a endpoints" }]}
                >
                  <Select
                    placeholder="Select endpoint"
                    onChange={(value) => {
                      handleEndpointChange(value);
                    }}
                    defaultValue={selectedEndpoint}
                    labelInValue
                  >
                    {endpoints.length > 0 &&
                      endpoints.map((endpoint) => (
                        <Option
                          key={endpoint.id}
                          value={endpoint.public_ip}
                          label={`${endpoint.host_name} (${endpoint.public_ip})`}
                        >
                          {endpoint.host_name} ({endpoint.public_ip})
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              {selectedEndpointType === "Endpoints Group" && (
                <Form.Item
                  name="endpoints groups"
                  label="Select Endpoints Group"
                  rules={[
                    {
                      required: true,
                      message: "Please select a endpoints group",
                    },
                  ]}
                >
                  <Select placeholder="Select endpoints group"></Select>
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      );
    }

    return null;
  };

  const handleNextClick = () => {
    form.validateFields().then(() => {
      setSelectedType(selectedTypeLocal);
      setSelectedApp(selectedAppLocal);

      nextTab();
    });
  };

  const fetchOU = async () => {
    let data = {
      org_Id: ORG_ID(),
      tenant_Id: DOMAIN_ID(),
    };
    try {
      const response = await axios.post(`${REACT_APP_API}/api/v1/policyService/listOU`, data, {
        headers: {
          "Content-Type": "application/json",
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });

      const fetchedOUs = response?.data?.data;

      if (Array.isArray(fetchedOUs)) {
        setAllOU(fetchedOUs);
      } else {
        setAllOU([]);
        console.error("Fetched OUs is not an array:", fetchedOUs);
      }
    } catch (error) {
      console.error("Error fetching OUs:", error);
    }
  };

  const getEndpointOsType = (endpoints, public_ip) => {
    const endpoint = endpoints.find((endpoint) => endpoint.public_ip === public_ip);
    if (endpoint) {
      setEndpointOSType(endpoint.os_name);
    }
  };

  return (
    <Row>
      <Col span={24} style={{ padding: "20px" }}>
        <Row>
          <Col
            span={18}
            style={{
              background: "rgb(219, 238, 255)",
              borderRadius: "8px",
              padding: "2rem",
            }}
          >
            <Form form={form} onFinish={handleNextClick} layout="vertical">
              <Form.Item label="Select Type" name="policyType">
                <Select
                  placeholder="Select type"
                  rules={[{ required: true, message: "Please select an endpoint" }]}
                  options={[
                    { label: "App", value: "App" },
                    { label: "Endpoints", value: "Endpoints" },
                  ]}
                  onChange={handleTypeChange}
                  defaultValue={selectedTypeLocal}
                />
              </Form.Item>
              {renderConditionalDropdown()}

              {selectedTypeLocal === "Endpoints" && (
                <Form.Item
                  label="Endpoint OU"
                  name="endpointOU"
                  style={{ marginTop: "1.5rem" }}
                  rules={[{ required: true, message: "Select an endpoint OU" }]}
                >
                  <Select
                    mode="multiple"
                    onChange={(e) => setSelectedOU(e)}
                    placeholder="Select Endpoint OU"
                  >
                    {allOU.map((ou) => (
                      <Option key={ou.ou_name} value={ou.ou_name}>
                        {ou.ou_name} <span style={{ fontWeight: 600 }}>({ou.ad_name})</span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Form>
          </Col>
        </Row>

        <Row style={{ marginTop: "1rem" }}>
          <Col span={18}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
