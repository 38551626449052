import React, { useState } from "react";
import "../../../styles/Unauthorized/signup.scss";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Result, message } from "antd";
import axios from "axios";
import { REACT_APP_API } from "../../../constants";
import { useNavigate } from "react-router";
import ResetPassword from "./ResetPassword";
import MfaCacheNotification from "./MfaCacheNotification";
import { ReactComponent as ACCESSDENIED } from "../../../assets/access-denied.svg";

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [isNotification, setIsNotification] = message.useMessage();
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [loginDetails, setLoginDetails] = useState(null);
  const [isSSOMfaEnabled, setIsSSOMfaEnabled] = useState(false);
  const [token, setToken] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isMFAFailed, setIsMFAFailed] = useState(false);
  const [isMFADenied, setIsMFADenied] = useState(false);

  const validateOnboarding = () => {
    //TODO: onboarding api call
    const onBoardingStatus = false; //Dummy variable need to change after API call
    setIsLoading(false);
    if (onBoardingStatus) {
      localStorage.setItem("isUserOnBoarded", false);
      navigate("/onboard");
    } else {
      navigate("/");
    }
  };

  const handleCheckSSOMFAEnable = (values, resData) => {
    const payload = {
      url: localStorage.getItem("URL"),
      // url: "kloudlearn.kloudone.prod.authnull.com",
      email: values.username,
      token: resData.access_token,
      RequestID: "",
    };
    axios
      .post(`${REACT_APP_API}/okta/ssomfa`, payload)
      .then(({ data }) => {
        console.log(data);
        localStorage.setItem("token", resData.access_token);
        setIsSSOMfaEnabled(false);
        if (data.first_login === "0") {
          setIsResetPassword(true);
        } else {
          validateOnboarding();
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSSOMfaEnabled(false);
        setIsMFADenied(true);
        if (err?.response?.data?.code === 401) {
          setIsMFADenied(true);
        } else if (err?.response?.status === 504) {
          setIsMFAFailed(true);
        } else {
          setIsMFADenied(true);
        }
      });
  };

  const handleFinish = (values) => {
    setLoginDetails(values);
    const loginData = {
      ...values,
      requestId: localStorage.getItem("requestId"),
      url: localStorage.getItem("URL"),
      // url: "kloudlearn.kloudone.prod.authnull.com",
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API}/okta/normalLogin`, loginData, {
        headers: {
          withCredentials: true,
        },
      })
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        setToken(res.data.access_token);
        // if (   NOTE: ASK PER HUSSAIN & ASIF REQUIREMENT ALWAYS SSOMFA IS ENABLED
        //   res.data.sso_mfa === true &&   (
        //   res.data.first_login === "1" ||
        //   res.data.first_login === "")
        // ) {
        //   handleCheckSSOMFAEnable(values, res.data);
        //   setIsSSOMfaEnabled(true);
        // } else if (res.data.first_login === "0") {
        //   setIsResetPassword(true);
        // }
        // else {
        //   localStorage.setItem("token", res.data.access_token);
        //   validateOnboarding();
        // }

        if (res.data.first_login === "0") {
          setIsResetPassword(true);
        } else {
          if (localStorage.getItem("URL") === "default.devsetup.authnull.com") {
            //NOTE : CHANGES ONLY FOR DEV TENANT "default.devsetup.authnull.com"
            localStorage.setItem("token", res.data.access_token);
            validateOnboarding();
          } else {
            handleCheckSSOMFAEnable(values, res.data);
            setIsSSOMfaEnabled(true);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        isNotification.open({
          type: "error",
          content:
            err?.response?.data?.code === 401
              ? "Incorrect email or password"
              : "Unable to Login, Please try again",
        });
      });
  };

  return (
    <>
      {setIsNotification}
      {!isResetPassword ? (
        <>
          {isSSOMfaEnabled ? (
            <MfaCacheNotification isLoading={isLoading} />
          ) : (
            <div className="login-container">
              <div className="login-box">
                {isMFAFailed ? (
                  <Result
                    status="403"
                    title=""
                    subTitle={"Request Timeout to Accept Notification"}
                    extra={
                      <Button
                        type="primary"
                        onClick={() => {
                          setIsMFAFailed(false);
                          setIsMFADenied(false);
                        }}
                      >
                        Back to Login
                      </Button>
                    }
                  />
                ) : isMFADenied ? (
                  // <Card
                  //   style={{
                  //     width: 500,
                  //   }}
                  // >
                  <>
                    <h2 style={{ textAlign: "center", marginBottom: "2rem" }}>
                      Unable to Verify SSO MFA
                    </h2>
                    <span style={{ display: "flex" }}>
                      <ACCESSDENIED style={{ height: 250 }} />
                    </span>

                    <Button
                      type="primary"
                      onClick={() => {
                        if (isMFADenied) {
                          setIsMFAFailed(false);
                          setIsMFADenied(false);
                        }
                      }}
                      style={{
                        display: "flex",
                        margin: "auto",
                        marginTop: "3rem",
                        marginBottom: "1rem",
                      }}
                    >
                      Back to Login
                    </Button>
                  </>
                ) : (
                  <>
                    <h2 className="title">Sign in to Tenant</h2>
                    <Form
                      form={form}
                      name="normal_login"
                      className="login-form"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={handleFinish}
                    >
                      <Form.Item
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Email",
                          },
                          {
                            type: "email",
                            message: "Please enter valid email",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          prefix={<UserOutlined className="site-form-item-icon" />}
                          placeholder="Email"
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Password",
                          },
                        ]}
                      >
                        <Input.Password
                          size="large"
                          prefix={<LockOutlined className="site-form-item-icon" />}
                          type="password"
                          placeholder="Password"
                        />
                      </Form.Item>

                      <div className="login-form-button">
                        <Form.Item>
                          <Button
                            size="large"
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            loading={isLoading}
                          >
                            Sign in
                          </Button>
                          <br />
                        </Form.Item>
                        <span>Or</span> <br />
                        <br />
                        <a href="">Forgot password ?</a>
                      </div>
                    </Form>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <ResetPassword
          loginDetails={loginDetails}
          token={token}
          setIsResetPassword={setIsResetPassword}
        />
      )}
    </>
  );
}

export default Login;
