import { MoreOutlined, InboxOutlined } from "@ant-design/icons";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { Col, Dropdown, Form, Row, Select, Menu, Tag, message, Drawer, Spin, Button } from "antd";
import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import AddPermissionModal from "../addPermissionModal";
import { AUTH_TOKEN, DOMAIN_ID, ORG_ID, REACT_APP_API } from "../../../../constants";
import { Link } from "react-router-dom";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

export default function EndpointRulesRequests() {
  const [form] = Form.useForm();

  const [notification, notificationHolder] = message.useMessage();
  const [endpointsData, setEndpointsData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [endpointUsers, setEndpointUsers] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [sourceIp, setSourceIp] = useState([]);
  const [destinationIP, setDestinationIP] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [viewRulePermission, setViewRulePermission] = useState(false);
  const [rulePermission, setRulePermission] = useState(null);
  const [isLoadingPermission, setIsLoadingPermission] = useState(false);
  const [permissionType, setPermissionType] = useState("");
  const [groupNames, setGroupNames] = useState([]);
  const [selectedRule, setSelectedRule] = useState("Endpoints");

  //pagination
  const PAGESIZE = 10;
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });

  useEffect(() => {
    fetchEndpointUsers();
    fetchSourceIP();
    fetchDestinationIP();
    fetchGroupNames();
  }, []);

  useEffect(() => {
    if (selectedRule === "AD") {
      fetchADGroup();
    } else {
      fetchEndpointsRules();
    }
  }, [appliedFilters, paginationParams, currentPage]);

  useEffect(() => {
    if (selectedRule === "AD") {
      fetchADGroup();
      setAppliedFilters([]);
    } else {
      fetchEndpointsRules();
      setAppliedFilters([]);
    }
  }, [selectedRule]);

  const columns = [
    {
      headerName: "Username",
      field: "username",
      key: "username",
      flex: 1,
    },
    {
      headerName: "Source IP",
      field: "source_ip",
      key: "source_ip",
      flex: 1,
      renderCell: (val) => (
        <Tag
          style={{
            cursor: "pointer",
            whiteSpace: "break-spaces",
          }}
          onClick={() => {
            applyFilter("sourceIp", val?.row?.source_ip);
          }}
        >
          {val?.row?.source_ip}
        </Tag>
      ),
    },
    {
      headerName: "Destination IP",
      field: "destination_ip",
      key: "destination_ip",
      flex: 1,
      renderCell: (val) => (
        <Tag
          style={{
            cursor: "pointer",
            whiteSpace: "break-spaces",
          }}
          onClick={() => {
            applyFilter("destinationIp", val?.row?.destination_ip);
          }}
        >
          {val?.row?.destination_ip}
        </Tag>
      ),
    },
    selectedRule === "AD" && {
      headerName: "Domain Name",
      field: "ou",
      key: "ou",
      flex: 1,
    },
    {
      headerName: "Protocol",
      field: "protocols",
      key: "protocols",
      flex: 0.7,
    },
    {
      headerName: "Created At",
      field: "created_at",
      key: "created_at",
      flex: 1.3,
      renderCell: (val) =>
        moment(val?.row?.created_at).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm:ss [PST]"),
    },
    selectedRule === "AD"
      ? {
          headerName: "Authentication Status",
          field: "authentication_status",
          key: "authentication_status",
          renderCell: (val) => (
            <Tag
              color={val?.row?.authentication_status?.toLowerCase() === "success" ? "green" : "red"}
            >
              {val?.row?.authentication_status}
            </Tag>
          ),
          flex: 1,
        }
      : {
          headerName: "Authentication Status",
          field: "authentication_status",
          key: "authentication_status",
          renderCell: (val) => (
            <Tag
              color={val?.row?.endpoint_auth_status?.toLowerCase() === "success" ? "green" : "red"}
            >
              {val?.row?.endpoint_auth_status}
            </Tag>
          ),
          flex: 1,
        },
    {
      headerName: "Status",
      field: "status",
      key: "status",
      renderCell: (val) => (
        <Tag color={val?.row?.status === "Active" ? "green" : "red"}>{val?.row?.status}</Tag>
      ),
      flex: 1,
    },
    {
      headerName: "Action",
      dataIndex: "id",
      key: "id",
      renderCell: (val) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <a
                  onClick={() => {
                    setSelectedRowData(val.row);
                    form.setFieldsValue(val.row);
                    setModalVisible(true);
                  }}
                >
                  Add Permission
                </a>
              </Menu.Item>
              <Menu.Item key="2">
                <a
                  onClick={() => {
                    if (selectedRule === "AD") {
                      fetchADRulePermission(val?.row?.id);
                      setViewRulePermission(true);
                    } else {
                      fetchRulePermission(val?.row?.id);
                      setViewRulePermission(true);
                    }
                  }}
                >
                  View Permission
                </a>
              </Menu.Item>
              {val?.row?.status === "Active" ? (
                <Menu.Item key="3" danger={true}>
                  <a
                    onClick={() => {
                      if (selectedRule === "AD") {
                        handleStatusChange(val?.row?.id, "Inactive", "ADGroupJob");
                      } else {
                        handleStatusChange(val?.row?.id, "Inactive", "EndpointRule");
                      }
                    }}
                  >
                    Deactivate
                  </a>
                </Menu.Item>
              ) : (
                <Menu.Item key="3">
                  <a
                    onClick={() => {
                      if (selectedRule === "AD") {
                        handleStatusChange(val?.row?.id, "Active", "ADGroupJob");
                      } else {
                        handleStatusChange(val?.row?.id, "Active", "EndpointRule");
                      }
                    }}
                  >
                    Activate
                  </a>
                </Menu.Item>
              )}
            </Menu>
          }
          placement="bottomLeft"
        >
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ].filter(Boolean);

  const fetchADGroup = async () => {
    setIsLoading(true);
    let payload = {
      org_Id: ORG_ID(),
      tenant_Id: DOMAIN_ID(),
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      filters:
        Object.keys(appliedFilters).length === 0
          ? [{ filterParameter: "", filterValue: "" }]
          : Object.entries(appliedFilters).map(([key, value]) => ({
              filterParameter: key,
              filterValue: value,
            })),
    };
    try {
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listADLogGroup`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      const tempdata = res?.data?.data;

      setEndpointsData(tempdata);
      setTotalCount(res?.data?.total_count);
      setCurrentPageModel({
        pageSize: 10,
        page: currentPage,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const updateADRule = async (payload) => {
    try {
      setIsUpdating(true);
      await axios.post(`${REACT_APP_API}/api/v1/policyService/createADGroupJob`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      notification.open({
        type: "success",
        content: "AD Rule added Successfully",
      });
      setModalVisible(false);
      setIsUpdating(false);
    } catch (error) {
      notification.open({
        type: "error",
        content: "Failed to add AD Rule",
      });
      setIsUpdating(false);
      console.log(error);
    }
  };

  const updateEndpointRule = async (payload) => {
    try {
      setIsUpdating(true);
      await axios.post(`${REACT_APP_API}/api/v1/policyService/UpdateEndpointRule`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      notification.open({
        type: "success",
        content: "AD Rule added Successfully",
      });
      setModalVisible(false);
      setIsUpdating(false);
    } catch (error) {
      notification.open({
        type: "error",
        content: "Failed to add AD Rule",
      });
      setIsUpdating(false);
      console.log(error);
    }
  };

  const handleUpdate = async (values) => {
    let rulePayload = {
      orgId: ORG_ID(),
      id: values.id,
      endpointPermissionType: values.permissionType,
      endpointPermissionValue: values.action,
    };

    let adPayload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      adId: "1",
      adGroup: values.adGroup,
      Username: values.username,
      permissionType: values.action,
    };

    if (selectedRule === "AD") {
      updateADRule(adPayload);
    } else {
      updateEndpointRule(rulePayload);
    }
  };

  const fetchEndpointUsers = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    try {
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listEndpointUser`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );

      const tempData = res?.data?.data.map((user) => {
        return {
          label: user,
          value: user,
        };
      });

      setEndpointUsers(tempData);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSourceIP = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    try {
      const res = await axios.post(`${REACT_APP_API}/api/v1/policyService/listSourceIP`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });

      const tempData = res?.data?.data.map((ip) => {
        return {
          label: ip,
          value: ip,
        };
      });

      setSourceIp(tempData);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDestinationIP = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    try {
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listDestinationIP`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );

      const tempData = res?.data?.data.map((ip) => {
        return {
          label: ip,
          value: ip,
        };
      });

      setDestinationIP(tempData);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEndpointsRules = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      filters:
        Object.keys(appliedFilters).length === 0
          ? [{ filterParameter: "", filterValue: "" }]
          : Object.entries(appliedFilters).map(([key, value]) => ({
              filterParameter: key,
              filterValue: value,
            })),
    };
    try {
      setIsLoading(true);
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listEndpointRule`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      setEndpointsData(res?.data?.data);
      setTotalCount(res?.data?.total_count);
      setCurrentPageModel({
        pageSize: 10,
        page: currentPage,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetchRulePermission = async (ruleId) => {
    let payload = {
      org_id: ORG_ID(),
      tenant_id: DOMAIN_ID(),
      rule_Id: ruleId,
    };
    try {
      setIsLoadingPermission(true);
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listViewPermission`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      setRulePermission(res?.data?.rules);
      setIsLoadingPermission(false);
    } catch (error) {
      console.log(error);
      setIsLoadingPermission(false);
    }
  };

  const fetchADRulePermission = async (jobId) => {
    let payload = {
      org_id: ORG_ID(),
      tenant_id: DOMAIN_ID(),
      job_Id: jobId,
    };
    try {
      setIsLoadingPermission(true);
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listViewADGroupJobs`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      setRulePermission(res?.data?.jobs);
      setIsLoadingPermission(false);
    } catch (error) {
      setIsLoadingPermission(false);
      console.log(error);
    }
  };

  const fetchGroupNames = async () => {
    let payload = {
      org_id: 84,
      tenant_id: DOMAIN_ID(),
      domainName: "AUTHULL3", // hardcoded
    };
    try {
      const res = await axios.post(`${REACT_APP_API}/api/v1/policyService/listADGroups`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      const tempData = res?.data?.data.map((groupName) => {
        return {
          label: groupName,
          value: groupName,
        };
      });
      setGroupNames(tempData);
    } catch (error) {
      console.log(error);
    }
  };

  const applyFilter = (type, value) => {
    setAppliedFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
    setCurrentPage(0);
  };

  const getAppliedFilterValue = (type) => {
    return appliedFilters[type] ?? null;
  };

  const handleStatusChange = async (id, status, type) => {
    try {
      let payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        id: id,
        type: type,
        status: status,
      };
      setIsLoading(true);
      await axios.post(`${REACT_APP_API}/api/v1/policyService/changeStatus`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      notification.open({
        type: "success",
        content: `${
          type === "EndpointRule"
            ? "Endpoint Rule"
            : type === "ADGroupJob"
            ? "AD Group Job"
            : "DIT Policy"
        } ${status} Successfully`,
      });
      setIsLoading(false);
      setAppliedFilters([]);

      if (type === "ADGroupJob") {
        fetchADGroup();
      } else {
        fetchEndpointsRules();
      }
    } catch (error) {
      notification.open({
        type: "error",
        content: "Something went wrong, try again.",
      });
      setIsLoading(false);
    }
  };

  return (
    <Row className="content-container">
      {notificationHolder}
      <Col span={24}>
        <Row justify="space-between">
          <h1 className="title">Requests</h1>
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={20} style={{ marginBottom: "1rem" }}>
          <Col span={6}>
            <h5 className="search-label">Filter By Username:</h5>
            <Select
              showSearch
              placeholder="Select User"
              style={{ width: "100%" }}
              options={endpointUsers}
              onChange={(val) => applyFilter("username", val)}
              value={getAppliedFilterValue("username")}
            />
          </Col>
          <Col span={6}>
            <h5 className="search-label">Source IP:</h5>
            <Select
              showSearch
              placeholder="Select Source IP"
              style={{ width: "100%" }}
              options={sourceIp}
              onChange={(val) => applyFilter("sourceIp", val)}
              value={getAppliedFilterValue("sourceIp")}
            />
          </Col>
          <Col span={6}>
            <h5 className="search-label">Destination IP:</h5>
            <Select
              showSearch
              placeholder="Select Destination IP"
              style={{ width: "100%" }}
              options={destinationIP}
              onChange={(val) => applyFilter("destinationIp", val)}
              value={getAppliedFilterValue("destinationIp")}
            />
          </Col>
          <Col span={6}>
            <h5 className="search-label">Status:</h5>
            <Select
              placeholder="Select Status"
              style={{ width: "100%" }}
              options={[
                { label: "Active", value: "Active" },
                { label: "Inactive", value: "Inactive" },
              ]}
              onChange={(val) => applyFilter("status", val)}
              value={getAppliedFilterValue("status")}
            />
          </Col>
          <Col span={6}>
            <h5 className="search-label">Log Type:</h5>
            <Select
              style={{ width: "100%" }}
              options={[
                { label: "AD", value: "AD" },
                { label: "Endpoints", value: "Endpoints" },
              ]}
              onChange={(val) => {
                setSelectedRule(val);
              }}
              defaultValue={selectedRule}
            />
          </Col>
        </Row>

        <Row justify="space-between" style={{ marginBottom: "2rem" }}>
          <Col>
            <p>{selectedRule && "Applied Filters:"}</p>
            <Tag>{`logType: ${selectedRule}`}</Tag>
            {Object.entries(appliedFilters).map(([key, value]) => {
              if (value) {
                return (
                  <Tag
                    key={key}
                    closable={true}
                    onClose={() => {
                      setAppliedFilters((prevFilters) => {
                        let tempFilters = { ...prevFilters };
                        delete tempFilters[key];
                        return tempFilters;
                      });
                    }}
                  >{`${key}: ${value}`}</Tag>
                );
              }
              return null;
            })}
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <DataGrid
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            noResultsOverlay: CustomNoRowsOverlay,
          }}
          style={{ border: "none" }}
          columns={columns}
          rows={endpointsData}
          loading={isLoading}
          paginationMode="server"
          filterMode="server"
          rowCount={totalCount}
          page={currentPage}
          paginationModel={currentPageModel}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          onPaginationModelChange={(params) => {
            setCurrentPage(params.page);
          }}
        />
      </Col>

      <Drawer
        title="View Rule Permission"
        onClose={() => setViewRulePermission(false)}
        open={viewRulePermission}
      >
        {isLoadingPermission ? (
          <div style={{ textAlign: "center" }}>
            <Spin />
          </div>
        ) : (
          <Row className="commit-container ">
            <Col className="version-history" span={24}>
              <div className="github-commit-card ">
                <div className="commit-details">
                  <p className="commit-message">Rule Permission</p>
                  <div className="commit-code">
                    <div className="code-line" style={{ marginTop: "1rem" }}>
                      <pre className="code-snippet">
                        <pre>{JSON.stringify(rulePermission, null, 2)}</pre>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Drawer>

      <AddPermissionModal
        fetchEndpointUsers={fetchEndpointUsers}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        isUpdating={isUpdating}
        selectedRowData={selectedRowData}
        permissionType={permissionType}
        setPermissionType={setPermissionType}
        groupNames={groupNames}
        form={form}
        handleUpdate={handleUpdate}
        selectedRule={selectedRule}
      />
    </Row>
  );
}
